<template>
  <b-container class="editPost">
    <article class="single-blog" style="overflow: visible">
      <b-row>
        <b-col md="12" class="pt-4 pl-4 pr-4">
          <h2 class="px-2" v-if="!postId">Napiši nov zapis</h2>
          <h2 class="px-2" v-if="postId">
            Uredi zapis "{{ this.post.title }}"
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-alert variant="warning" show
            ><i class="fa fa-info-circle"></i> V primeru tehničnih težav pri
            vnosu zapisa nam pišite na
            <a href="mailto:info@dobra-pot.si">info@dobra-pot.si</a>.
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-card bg-variant="light" v-if="getUser.admin && user" class="mb-5">
            <template #header>
              <h4 class="mb-0 mt-0">
                <i class="fa fa-lock"></i> Podatki o avtorju zapisa
              </h4>
            </template>
            <b-card-body>
              <b-row>
                <b-col md="12 single-about">
                  <b-row>
                    <div
                      class="about-us-img h-100 float-left pb-0 col-md-4 col-12 p-5"
                    >
                      <div
                        class="rounded-circle text-center overflow-hidden"
                        style="width: 240px; height: 240px;  display: flex; align-content: center; align-items: center; text-align: center;"
                      >
                        <b-img
                          v-if="user.profileImg"
                          :src="user.profileImg"
                          :alt="user.displayName"
                          style="height: 240px; width: 240px; object-fit: cover; max-width: initial"
                        />
                        <i
                          v-if="!user.profileImg"
                          class="far fa-user-circle fa-7x d-block w-100"
                        ></i>
                      </div>
                    </div>
                    <div class="about-us-text col-12 col-md-8 ">
                      <h3 class="text-uppercase">
                        {{ user.firstName + " " + user.lastName }}
                      </h3>
                      <h5>{{ user.displayName }}</h5>
                      <p>
                        <em>{{ user.bio }}</em>
                      </p>
                      <dl>
                        <dt>Letnica rojstva</dt>
                        <dd>
                          <i class="fa fa-star"></i
                          ><span class="pl-1">{{ user.yearOfBirth }}</span>
                        </dd>
                        <dt>Email</dt>
                        <dd>
                          <i class="fa fa-envelope"></i>
                          <a class="pl-1" :href="'mailto:' + user.email">{{
                            user.email
                          }}</a>
                        </dd>
                        <dt>Telefon</dt>
                        <dd>
                          <i class="fa fa-phone"></i>
                          <a class="pl-1" :href="'tel:' + user.phone">{{
                            user.phone
                          }}</a>
                        </dd>
                      </dl>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <div v-if="!getUser.admin">
            <b-card>
              <template #header>
                <h4 class="mb-0 mt-0">Naslov in povzetek zapisa</h4>
              </template>
              <b-card-body>
                <b-form-group label="Vnesite naslov zapisa*" label-for="title">
                  <b-form-input
                    type="text"
                    id="title"
                    v-model="post.title"
                    trim
                  ></b-form-input>

                  <div v-if="$v.post.title.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.post.title.required"
                    >
                      Polje "Naslov zapisa" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.post.title.minLength"
                    >
                      Polje "Naslov zapisa" mora vsebovati vsaj
                      {{ $v.post.title.$params.minLength.min }} črke.
                    </b-alert>
                  </div>
                </b-form-group>

                <b-form-group
                  label="Vnesite povzetek zapisa*"
                  label-for="summary"
                  class="mt-4"
                >
                  <b-form-textarea
                    id="summary"
                    v-model="post.summary"
                    rows="3"
                    trim
                  ></b-form-textarea>

                  <div v-if="$v.post.summary.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.post.summary.required"
                    >
                      Polje "Povzetek zapisa" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.post.summary.maxLength"
                    >
                      Polje "Povzetek zapisa" ne sme biti daljše od
                      {{ $v.post.summary.$params.maxLength.max }} znakov.
                    </b-alert>
                  </div>
                </b-form-group>
              </b-card-body>
            </b-card>

            <b-card class="mt-5">
              <template #header>
                <h4 class="mb-0 mt-0">Besedilna vsebina zapisa</h4>
              </template>
              <b-card-body>
                <b-form-group
                  label="Vnesite vsebino zapisa"
                  label-for="content"
                >
                  <div v-if="getUser.admin && user">
                    <a
                      class="btn btn-link editCode p-3"
                      @click="
                        () => {
                          showPostSource = !showPostSource;
                        }
                      "
                      >{{
                        showPostSource
                          ? "Skrij prikaz izvorne kode"
                          : "Pokaži izvorno kodo"
                      }}</a
                    >
                    <b-textarea
                      rows="20"
                      v-if="showPostSource"
                      v-model="post.content"
                      style="overflow-y: auto;"
                    ></b-textarea>
                  </div>
                  <vue-editor
                    v-if="!showPostSource"
                    id="content"
                    v-model="post.content"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                </b-form-group>
              </b-card-body>
            </b-card>
          </div>

          <div v-if="getUser.admin">
            <b-card no-body>
              <b-tabs pills card>
                <b-tab title="Slovensko" active>
                  <div>
                    <b-card>
                      <template #header>
                        <h4 class="mb-0 mt-0">Naslov in povzetek zapisa</h4>
                      </template>
                      <b-card-body>
                        <b-form-group
                          label="Vnesite naslov zapisa*"
                          label-for="title"
                        >
                          <b-form-input
                            type="text"
                            id="title"
                            v-model="post.title"
                            trim
                          ></b-form-input>

                          <div v-if="$v.post.title.$dirty">
                            <b-alert
                              show
                              variant="danger"
                              class="error col mt-1"
                              v-if="!$v.post.title.required"
                            >
                              Polje "Naslov zapisa" ne sme biti prazno.
                            </b-alert>
                            <b-alert
                              show
                              variant="danger"
                              class="error col mt-1"
                              v-if="!$v.post.title.minLength"
                            >
                              Polje "Naslov zapisa" mora vsebovati vsaj
                              {{ $v.post.title.$params.minLength.min }} črke.
                            </b-alert>
                          </div>
                        </b-form-group>

                        <b-form-group
                          label="Vnesite povzetek zapisa*"
                          label-for="summary"
                          class="mt-4"
                        >
                          <b-form-textarea
                            id="summary"
                            v-model="post.summary"
                            rows="3"
                            trim
                          ></b-form-textarea>

                          <div v-if="$v.post.summary.$dirty">
                            <b-alert
                              show
                              variant="danger"
                              class="error col mt-1"
                              v-if="!$v.post.summary.required"
                            >
                              Polje "Povzetek zapisa" ne sme biti prazno.
                            </b-alert>
                            <b-alert
                              show
                              variant="danger"
                              class="error col mt-1"
                              v-if="!$v.post.summary.maxLength"
                            >
                              Polje "Povzetek zapisa" ne sme biti daljše od
                              {{ $v.post.summary.$params.maxLength.max }}
                              znakov.
                            </b-alert>
                          </div>
                        </b-form-group>
                      </b-card-body>
                    </b-card>

                    <b-card class="mt-5">
                      <template #header>
                        <h4 class="mb-0 mt-0">Besedilna vsebina zapisa</h4>
                      </template>
                      <b-card-body>
                        <b-form-group
                          label="Vnesite vsebino zapisa"
                          label-for="content"
                        >
                          <div v-if="getUser.admin && user">
                            <a
                              class="btn btn-link editCode p-3"
                              @click="
                                () => {
                                  showPostSource = !showPostSource;
                                }
                              "
                              >{{
                                showPostSource
                                  ? "Skrij prikaz izvorne kode"
                                  : "Pokaži izvorno kodo"
                              }}</a
                            >
                            <b-textarea
                              rows="20"
                              v-if="showPostSource"
                              v-model="post.content"
                              style="overflow-y: auto;"
                            ></b-textarea>
                          </div>
                          <vue-editor
                            v-if="!showPostSource"
                            id="content"
                            v-model="post.content"
                            :editor-toolbar="customToolbar"
                          ></vue-editor>
                        </b-form-group>
                      </b-card-body>
                    </b-card>
                  </div>
                </b-tab>
                <b-tab title="English">
                  <div>
                    <b-card>
                      <template #header>
                        <h4 class="mb-0 mt-0">Post title and summary</h4>
                      </template>
                      <b-card-body>
                        <b-form-group
                          label="Enter the post title*"
                          label-for="title"
                        >
                          <b-form-input
                            type="text"
                            id="title"
                            v-model="post.en_title"
                            trim
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="Enter a summary of your post*"
                          label-for="summary"
                          class="mt-4"
                        >
                          <b-form-textarea
                            id="summary"
                            v-model="post.en_summary"
                            rows="3"
                            trim
                          ></b-form-textarea>
                        </b-form-group>
                      </b-card-body>
                    </b-card>

                    <b-card class="mt-5">
                      <template #header>
                        <h4 class="mb-0 mt-0">Text content</h4>
                      </template>
                      <b-card-body>
                        <b-form-group
                          label="Enter the content of your post"
                          label-for="content"
                        >
                          <div v-if="getUser.admin && user">
                            <a
                              class="btn btn-link editCode p-3"
                              @click="
                                () => {
                                  showPostSource = !showPostSource;
                                }
                              "
                              >{{
                                showPostSource
                                  ? "Hide display of native code"
                                  : "Show native code"
                              }}</a
                            >
                            <b-textarea
                              rows="20"
                              v-if="showPostSource"
                              v-model="post.en_content"
                              style="overflow-y: auto;"
                            ></b-textarea>
                          </div>
                          <vue-editor
                            v-if="!showPostSource"
                            id="content"
                            v-model="post.en_content"
                            :editor-toolbar="customToolbar"
                          ></vue-editor>
                        </b-form-group>
                      </b-card-body>
                    </b-card>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>

          <b-card class="mt-5 photos">
            <template #header>
              <h4 class="mb-0 mt-0">Fotografije</h4>
              <!--            {{ photos }}-->
              <!--            {{ photoPreviews }}-->
            </template>
            <div>
              <div class="p-4 mb-3 bg-dark" v-if="photoPreviews.length > 0">
                <b-row>
                  <b-col
                    md="3"
                    sm="6"
                    xs="12"
                    class="photoPreview my-3 relative"
                    v-for="(preview, index) in photoPreviews"
                    :key="'photoPreview_' + index"
                  >
                    <b-img thumbnail :src="preview" alt=""></b-img>
                    <caption
                      v-if="post.photosCaptions"
                      v-b-tooltip.hover
                      :title="'Vnesite pripis k sliki'"
                      @click="editCaption(index)"
                    >
                      <span>{{
                        post.photosCaptions[index]
                          ? post.photosCaptions[index]
                          : ""
                      }}</span>
                      <a class="btn btn-link btn-sm"
                        ><i class="fa fa-edit"></i
                      ></a>
                    </caption>
                    <a class="removeItem" @click="removeImage(preview, index)"
                      ><i class="fa fa-times"></i
                    ></a>
                  </b-col>
                </b-row>
                <!--              progress: {{ uploadValue }}%-->
                <!--              <a class="btn" @click="uploadImages()">Upload</a>-->
              </div>

              <div class="clearfix"></div>

              <div class="photosInput pb-0">
                <label
                  for="photos"
                  class="btn btn-primary text-white py-2 px-3"
                  style="opacity: 1;"
                  v-if="!videos"
                >
                  <i class="fa fa-plus"></i> Pripnite slike (prva bo krovna
                  slika zapisa)
                </label>
                <b-form-file
                  id="photos"
                  v-model="photos"
                  :directory="false"
                  :size="'lg'"
                  placeholder="Izberi slikovne datoteke ali jih povleci v to polje..."
                  drop-placeholder="Spusti datoteke..."
                  :multiple="true"
                  @change="previewImage"
                  accept="image/*"
                  style="display: none;"
                ></b-form-file>
              </div>

              <!--              <div class="photosInput">-->
              <!--                <b-form-group-->
              <!--                  label="Pripnite slike (prva bo krovna slika zapisa)"-->
              <!--                  label-for="photos"-->
              <!--                >-->
              <!--                  <b-form-file-->
              <!--                    v-model="photos"-->
              <!--                    :directory="false"-->
              <!--                    :size="'lg'"-->
              <!--                    placeholder="Izberi slikovne datoteke ali jih povleci v to polje..."-->
              <!--                    drop-placeholder="Spusti datoteke..."-->
              <!--                    :multiple="true"-->
              <!--                    @change="previewImage"-->
              <!--                    accept="image/*"-->
              <!--                  ></b-form-file>-->
              <!--                </b-form-group>-->
              <!--              </div>-->
            </div>
          </b-card>

          <b-card class="mt-5 videos" id="videosSection">
            <loading
              :active.sync="isLoadingVideo"
              :color="'#408521'"
              :is-full-page="false"
            ></loading>

            <template #header>
              <h4 class="mb-0 mt-0">
                Video posnetki
              </h4>
            </template>
            <b-card-body>
              <b-alert variant="warning" show
                ><i class="fa fa-info-circle"></i> Dovoljeno je nalaganje
                datotek do 500 MB. Najprimernejši je mp4 format. Za pretrvorbo
                posnetka v mp4 lahko uporabite
                <a target="_blank" href="https://convert-video-online.com"
                  >to brezplačno spletno orodje</a
                >.
              </b-alert>

              <b-container
                class="p-4 mb-3 bg-dark"
                v-if="videoPreviews.length > 0"
              >
                <b-row>
                  <b-col
                    md="3"
                    sm="6"
                    xs="12"
                    class="photoPreview my-3 relative"
                    v-for="(preview, index) in videoPreviews"
                    :key="'videoPreview_' + index"
                  >
                    <div style="min-width: 220px;">
                      <video-player
                        class="video-player-box"
                        :id="'video_' + index"
                        :ref="'video_player_' + index"
                        :options="{
                          poster: thumbnails[index] ? thumbnails[index] : null,
                          height: '160',
                          width: '220',
                          autoplay: false,
                          fluid: false,
                          crossOrigin: 'anonymous',
                          muted: false,
                          language: 'sl',
                          playbackRates: [0.7, 1.0, 1.5, 2.0],
                          sources: [{ type: 'video/mp4', src: preview.url.replace('dl=0', 'raw=1') }]
                        }"
                        @loadeddata="e => onPlayerDataLoaded(e, index)"
                      >
                      </video-player>
                    </div>

                    <!--                    <div style="visibility: hidden; width: 1px; height: 1px; overflow: auto">-->
                    <!--                      <canvas :id="'canvas_' + index" style="height: 200px; width: 20px;"></canvas>-->
                    <!--                    </div>-->

                    <a class="removeItem" @click="removeVideo(preview, index)"
                      ><i class="fa fa-times"></i
                    ></a>
                  </b-col>
                </b-row>
              </b-container>

              <div class="videoInput pb-0">
                <label
                  for="videos"
                  class="btn btn-primary text-white py-2 px-3"
                  style="opacity: 1;"
                  v-if="!videos"
                >
                  <i class="fa fa-plus"></i> Pripnite video posnetek
                </label>
                <b-form-file
                  id="videos"
                  v-model="videos"
                  :size="'lg'"
                  placeholder="Izberi video datoteko ali jo povleci v to polje..."
                  drop-placeholder="Spusti datoteko..."
                  :multiple="false"
                  accept="video/*"
                  style="display: none;"
                ></b-form-file>

                <a v-if="videos" class="btn btn-success" @click="uploadVideo()"
                  ><i class="fa fa-upload"></i> Naložite video posnetek
                  <b>{{
                    videos && videos.name ? videos.name : "datoteka"
                  }}</b></a
                >
                <div class="mt-5">
                  <a
                    v-if="videos"
                    class="btn btn-sm btn-secondary"
                    @click="
                      () => {
                        videos = null;
                      }
                    "
                    >Prekliči</a
                  >
                </div>
              </div>

              <!--              <div class="videoInput">-->
              <!--                <b-form-group label="Pripnite video posnetek" label-for="videos">-->
              <!--                  <b-form-file-->
              <!--                    v-model="videos"-->
              <!--                    :size="'lg'"-->
              <!--                    placeholder="Izberi video datoteko ali jo povleci v to polje..."-->
              <!--                    drop-placeholder="Spusti datoteko..."-->
              <!--                    :multiple="false"-->
              <!--                    accept="video/*"-->
              <!--                  ></b-form-file>-->
              <!--                </b-form-group>-->

              <!--                <a class="btn btn-success" @click="uploadVideo()"-->
              <!--                  ><i class="fa fa-cloud-upload"></i> Naložite video</a-->
              <!--                >-->
              <!--              </div>-->
            </b-card-body>
          </b-card>

          <b-card class="mt-5 recordings" id="recordingsSection">
            <loading
              :active.sync="isLoadingRecording"
              :color="'#408521'"
              :is-full-page="false"
            ></loading>

            <template #header>
              <h4 class="mb-0 mt-0">
                Zvočni posnetki
              </h4>
            </template>
            <b-card-body>
              <b-alert variant="warning" show
                ><i class="fa fa-info-circle"></i> Dovoljeno je nalaganje
                datotek do 200 MB. Najprimernejši je mp3 format. Za pretrvorbo
                posnetka v mp3 lahko uporabite
                <a target="_blank" href="https://online-audio-converter.com/"
                  >to brezplačno spletno orodje</a
                >.
              </b-alert>

              <b-container
                class="p-4 mb-3 bg-dark"
                v-if="recordingPreviews.length > 0"
              >
                <b-row>
                  <b-col
                    md="6"
                    sm="12"
                    xs="12"
                    class="photoPreview my-3 relative"
                    v-for="(recording, index) in recordingPreviews"
                    :key="'recordingPreview_' + index"
                  >
                    <div style="background: #f4f0e2;" class="img-thumbnail">
                      <mini-audio :audio-source="recording"></mini-audio>
                    </div>

                    <a
                      class="removeItem"
                      @click="removeRecording(recording, index)"
                      ><i class="fa fa-times"></i
                    ></a>
                  </b-col>
                </b-row>
              </b-container>

              <div class="recordingInput pb-0">
                <label
                  for="recordings"
                  class="btn btn-primary text-white py-2 px-3"
                  style="opacity: 1;"
                  v-if="!recordings"
                >
                  <i class="fa fa-plus"></i> Pripnite zvočni posnetek
                </label>
                <b-form-file
                  id="recordings"
                  v-model="recordings"
                  :size="'lg'"
                  placeholder="Izberi zvočno datoteko ali jo povleci v to polje..."
                  drop-placeholder="Spusti datoteko..."
                  :multiple="false"
                  accept="audio/*"
                  style="display: none;"
                ></b-form-file>

                <a
                  v-if="recordings"
                  class="btn btn-success"
                  @click="uploadRecording()"
                  ><i class="fa fa-upload"></i> Naložite zvočni posnetek
                  <b>{{
                    recordings && recordings.name ? recordings.name : "datoteka"
                  }}</b></a
                >
                <div class="mt-5">
                  <a
                    v-if="recordings"
                    class="btn btn-sm btn-secondary"
                    @click="
                      () => {
                        recordings = null;
                      }
                    "
                    >Prekliči</a
                  >
                </div>
              </div>

              <!--              <div class="recordingInput">-->
              <!--                <b-form-group-->
              <!--                  label="Pripnite zvočni posnetek"-->
              <!--                  label-for="recordings"-->
              <!--                >-->
              <!--                  <b-form-file-->
              <!--                    id="recordings"-->
              <!--                    v-model="recordings"-->
              <!--                    :size="'lg'"-->
              <!--                    placeholder="Izberi zvočno datoteko ali jo povleci v to polje..."-->
              <!--                    drop-placeholder="Spusti datoteko..."-->
              <!--                    :multiple="false"-->
              <!--                    accept="audio/*"-->
              <!--                  ></b-form-file>-->
              <!--                </b-form-group>-->

              <!--                <a class="btn btn-success" @click="uploadRecording()"-->
              <!--                  ><i class="fa fa-cloud-upload"></i> Naložite zvočni posnetek</a-->
              <!--                >-->
              <!--              </div>-->
            </b-card-body>
          </b-card>

          <b-card class="mt-5">
            <template #header>
              <h4 class="mb-0 mt-0">Razvrstitev zapisa</h4>
            </template>
            <b-card-body>
              <b-form-group
                label="Regija, na katero se zapis nanaša*"
                label-for="post_region"
              >
                <v-select
                  v-model="post.region"
                  id="post_region"
                  name="post_region"
                  :options="[
                    'pomurska regija',
                    'podravska regija',
                    'koroška regija',
                    'savinjska regija',
                    'zasavska regija',
                    'posavska regija',
                    'jugovzhodna Slovenija',
                    'osrednjeslovenska regija',
                    'gorenjska regija',
                    'primorsko-notranjska regija',
                    'goriška regija',
                    'obalno-kraška regija',
                    'celotno območje Slovenije',
                    'tujina'
                  ]"
                >
                  <span slot="no-options">Ni zadetkov.</span>
                </v-select>

                <div v-if="$v.post.region.$dirty">
                  <b-alert
                    show
                    variant="danger"
                    class="error col mt-1"
                    v-if="!$v.post.region.required"
                  >
                    Polje "Regija, kjer na katero se zapis nanaša" ne sme biti
                    prazno.
                  </b-alert>
                </div>
              </b-form-group>

              <b-form-group
                label="Geolokacija na katero se zapis nanaša (začnite vpisovati naslov in nato izberite ponujeno možnost ALI vnesite koordinate v spodnji polji)"
                label-for="postLocation"
              >
                <gmap-autocomplete
                  ref="postLocation"
                  id="postLocation"
                  class="form-control"
                  :country="['si']"
                  :options="{ componentRestrictions: { country: 'si' } }"
                  @place_changed="
                    data => {
                      setGeolocationData(data);
                    }
                  "
                  placeholder="začnite vpisovati naslov"
                  :value="post.geolocation.address"
                  :select-first-on-enter="true"
                ></gmap-autocomplete>
              </b-form-group>

              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Zemljepisna širina (latitude)"
                    label-for="geolocationLat"
                  >
                    <b-form-input
                      type="text"
                      id="geolocationLat"
                      v-model="post.geolocation.lat"
                      trim
                      @change="
                        post.geolocation.lat = post.geolocation.lat.replace(
                          ',',
                          '.'
                        )
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Zemljepisna dolžina (longitude)"
                    label-for="geolocationLon"
                  >
                    <b-form-input
                      type="text"
                      id="geolocationLon"
                      v-model="post.geolocation.lon"
                      trim
                      @change="
                        post.geolocation.lon = post.geolocation.lon.replace(
                          ',',
                          '.'
                        )
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group label="Glavna zvrst*" label-for="category">
                <v-select
                  v-if="categories"
                  v-model="post.categoryId"
                  id="selectedCategory"
                  name="selectedCategory"
                  label="description"
                  :options="categories"
                  :reduce="item => item.id"
                  @input="
                    () => {
                      post.subcategoryId = null;
                      getSubcategories();
                    }
                  "
                >
                  <span slot="no-options">Ni zadetkov.</span>

                  <template slot="option" slot-scope="item">
                    <div class="py-2">
                      <b>{{ item.name }}</b>
                      <div>
                        <em>{{ item.description }}</em>
                      </div>
                    </div>
                  </template>

                  <template slot="selected-option" slot-scope="item">
                    <b>{{ item.name }}</b>
                    <div>
                      <em>{{ item.description }}</em>
                    </div>
                  </template>
                </v-select>

                <div v-if="$v.post.categoryId.$dirty">
                  <b-alert
                    show
                    variant="danger"
                    class="error col mt-1"
                    v-if="!$v.post.categoryId.required"
                  >
                    Polje "Glavna zvrst" ne sme biti prazno.
                  </b-alert>
                </div>
              </b-form-group>

              <b-form-group
                label="Podzvrst*"
                label-for="subcategory"
                v-if="post.categoryId != 6"
              >
                <!--              {{ subcategories }}-->
                <v-select
                  v-if="subcategories"
                  v-model="post.subcategoryId"
                  id="selectedSubcategory"
                  name="selectedSubcategory"
                  label="name"
                  :options="subcategories"
                  :reduce="item => item.id"
                  @input="
                    () => {
                      setCategory();
                    }
                  "
                >
                  <span slot="no-options">Ni zadetkov.</span>

                  <template slot="option" slot-scope="item">
                    <div class="py-2">
                      {{ item.name }}
                    </div>
                  </template>

                  <template slot="selected-option" slot-scope="item">
                    {{ item.name }}
                  </template>
                </v-select>

                <div v-if="$v.post.subcategoryId.$dirty">
                  <b-alert
                    show
                    variant="danger"
                    class="error col mt-1"
                    v-if="!$v.post.subcategoryId.required"
                  >
                    Polje "Podzvrst" ne sme biti prazno.
                  </b-alert>
                </div>
              </b-form-group>

              <b-form-group
                label="Ključne besede (tagi; po vnosu vsake besede pritisnite tipko enter)"
                label-for="tags"
              >
                <vue-tags-input
                  class="w-100"
                  v-model="tag"
                  placeholder="Izberi ali vpiši ključne besede"
                  :tags="post.tags"
                  :autocomplete-items="filteredItems"
                  @tags-changed="
                    newTags => {
                      post.tags = newTags;
                    }
                  "
                />
              </b-form-group>
            </b-card-body>
          </b-card>

          <b-card class="mt-5" v-if="post.informant">
            <template #header>
              <h4 class="mb-0 mt-0">
                Podatki o pričevalcu (neobvezno)
                <p>
                  Poleg podatkov, ki so javno objavljeni, se podrobnejši podatki
                  o pričevalcih/nosilcih spomina (v kolikor jih uporabnik vnese)
                  shranijo v arhiv. Dostop do teh podatkov po potrebi omogočamo
                  znanstvenikom, etnologom in drugim strokovnjakom za namen
                  izvajanja zgodovinskih raziskav, etnoloških raziskav oziroma
                  raziskav na področju kulturne dediščine, ki služijo splošni
                  družbeni dobrobiti, napredku znanosti in razumevanja družbene
                  zgodovine, tehnološkemu napredku, javnemu interesu
                </p>
                <div>
                  <small>
                    Če izrecno želite, da so podatki vidni, jih vpišite v polje
                    za besedilno vsebino na vrhu te strani.
                  </small>
                </div>
              </h4>
            </template>
            <b-card-body>
              <b-form-checkbox
                class="mb-3"
                id="informant_same_as_user"
                v-model="post.informant.same_as_user"
                name="informant_same_as_user"
                :value="true"
                :unchecked-value="false"
                @input="prefillInformantFields()"
              >
                Pričevalec/-ka tega zapisa sem jaz
              </b-form-checkbox>
              <b-form-checkbox
                class="mb-3"
                id="isNoLongerAlive"
                :disabled="post.informant.same_as_user"
                v-model="post.informant.isNoLongerAlive"
                name="isNoLongerAlive"
                :value="true"
                :unchecked-value="false"
              >
                Pričevalec/-ka je preminil/-a
              </b-form-checkbox>
              <b-form-group label="Ime" label-for="recordings">
                <b-form-input
                  type="text"
                  id="informant_first_name"
                  v-model="post.informant.firstName"
                  :disabled="post.informant.same_as_user"
                  trim
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Priimek" label-for="informant_last_name">
                <b-form-input
                  type="text"
                  id="informant_last_name"
                  v-model="post.informant.lastName"
                  :disabled="post.informant.same_as_user"
                  trim
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Naslov (začnite vpisovati in nato izberite ponujeno možnost)"
                label-for="informant_address"
              >
                <gmap-autocomplete
                  ref="informant_address"
                  id="informant_address"
                  class="form-control"
                  :country="['si']"
                  :options="{ componentRestrictions: { country: 'si' } }"
                  @place_changed="
                    data => {
                      post.informant.address = data.formatted_address;
                    }
                  "
                  placeholder="začnite vpisovati naslov"
                  :value="post.informant.address"
                  :select-first-on-enter="true"
                ></gmap-autocomplete>

                <!--              <b-form-input-->
                <!--                type="text"-->
                <!--                id="informant_address"-->
                <!--                v-model="post.informant.address"-->
                <!--                trim-->
                <!--              ></b-form-input>-->
              </b-form-group>
              <b-form-group label="Hišno ime" label-for="informant_house_name">
                <b-form-input
                  type="text"
                  id="informant_house_name"
                  v-model="post.informant.house_name"
                  trim
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Poklic / izobrazba"
                label-for="informant_education_lebel"
              >
                <b-form-input
                  type="text"
                  id="informant_education_lebel"
                  v-model="post.informant.education_level"
                  trim
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="V primeru, da ste se priselili, izberite iz katere regije izvorno prihajate"
                label-for="informant_region_of_birth"
              >
                <v-select
                  v-model="post.informant.region_of_birth"
                  id="informant_region_of_birth"
                  name="informant_region_of_birth"
                  :options="[
                    'pomurska regija',
                    'podravska regija',
                    'koroška regija',
                    'savinjska regija',
                    'zasavska regija',
                    'posavska regija',
                    'jugovzhodna Slovenija',
                    'osrednjeslovenska regija',
                    'gorenjska regija',
                    'primorsko-notranjska regija',
                    'goriška regija',
                    'obalno-kraška regija',
                    'tujina'
                  ]"
                >
                  <span slot="no-options">Ni zadetkov.</span>
                </v-select>
              </b-form-group>
              <b-form-group
                label="Opišite, kako ste pridobili znanje o tematiki, ki jo vpisujete"
                label-for="informant_post_background_story"
                class="mt-4"
              >
                <b-form-textarea
                  id="informant_post_background_story"
                  v-model="post.informant.post_background_story"
                  rows="3"
                  trim
                ></b-form-textarea>
              </b-form-group>
            </b-card-body>
          </b-card>

          <b-card class="mt-4" bg-variant="light" v-if="getUser.admin">
            <template #header>
              <h4 class="mb-0 mt-0">
                <i class="fa fa-lock"></i> Nastavitve in komentar ocenjevalca
              </h4>
            </template>
            <div>
              <b-form-checkbox
                class="mb-3"
                id="recordFromNationalRegistry"
                v-model="post.recordFromNationalRegistry"
                name="recordFromNationalRegistry"
                :value="true"
                :unchecked-value="false"
              >
                Vsebina iz Registra nesnovne kulturne dediščine
              </b-form-checkbox>

              <b-form-group
                label="Vnesite komentar ob pregledu zapisa (npr. zabeležke o tem, kaj naj avtor zapisa popravi / dopolni)"
                label-for="reviewersComment"
              >
                <vue-editor
                  id="reviewersComment"
                  v-model="post.reviewersComment"
                  :editor-toolbar="customToolbar"
                ></vue-editor>
              </b-form-group>

              <b-form-group
                label="Sorodne objave"
                label-for="connectedPosts"
                class="mb-5 position-relative"
              >
                <v-select
                  v-if="posts"
                  :options="posts"
                  v-model="post.connectedPosts"
                  id="connectedPosts"
                  name="connectedPosts"
                  :getOptionLabel="item => item.title"
                  multiple
                  :reduce="item => item.id"
                  @input="$forceUpdate()"
                >
                  <template v-slot:option="item">
                    <div class="py-2">
                      <b>{{ item.title }}</b>
                      <div>
                        <em>{{ item.summary }}</em>
                      </div>
                    </div>
                  </template>

                  <template v-slot:selected-option="item">
                    <b>{{ item.title }}</b>
                    <div>
                      <em>{{ item.summary }}</em>
                    </div>
                  </template>

                  <template #no-options>
                    Ni zadetkov.
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </article>
    <!--    <b-row>-->
    <!--      <b-col md="12">-->
    <!--        <pre style="overflow: hidden">-->
    <!--        {{ post }}-->
    <!--          </pre>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <b-row v-if="!postId || !post.deleted">
      <b-col md="4" class="mt-1">
        <b-button
          variant="danger"
          block
          v-if="
            postId && (!post.submitted || (post.submitted && getUser.admin))
          "
          @click.stop="beforeDeletePost()"
          ><i class="fas fa-trash mr-2"></i> Izbriši
        </b-button>
      </b-col>
      <b-col md="4" class="mt-2">
        <b-button
          variant="info"
          block
          @click.stop="submitPost()"
          v-if="!post.submitted && !post.submitted"
          ><i class="fas fa-share-square mr-2"></i>
          <span v-if="!postId">Shrani in pošlji</span
          ><span v-if="postId">Shrani spremembe in pošlji</span></b-button
        >

        <b-button
          variant="success"
          block
          v-if="postId && post.submitted && getUser.admin && !post.confirmed"
          @click.stop="confirmPost()"
          ><i class="fas fa-check mr-2"></i> Shrani spremembe in OBJAVI
        </b-button>
        <b-button
          variant="warning"
          block
          v-if="postId && post.submitted && getUser.admin && !post.confirmed"
          @click.stop="returnPostToAuthor()"
          ><i class="fas fa-reply mr-2"></i> Shrani spremembe in VRNI zapis
          avtorju v urejanje
        </b-button>
        <b-button
          variant="warning"
          block
          v-if="postId && post.submitted && getUser.admin && post.confirmed"
          @click.stop="unconfirmPost()"
          ><i class="fas fa-times mr-2"></i> UMAKNI IZ OBJAVLJENIH
        </b-button>
      </b-col>
      <b-col md="4" class="mt-2">
        <b-button
          variant="primary"
          block
          v-if="!postId"
          @click.stop="savePost()"
          ><i class="fas fa-save mr-2"></i> Shrani osnutek
        </b-button>
        <b-button
          variant="primary"
          block
          v-if="
            postId && (!post.submitted || (post.submitted && getUser.admin))
          "
          @click.stop="updatePost()"
          ><i class="fas fa-save mr-2"></i> Shrani spremembe
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="my-2">
        <b-button
          variant="default"
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Prekliči
        </b-button>
      </b-col>
    </b-row>
    <loading
      :active.sync="isLoading"
      :color="'#408521'"
      :is-full-page="false"
    ></loading>

    <b-modal
      id="captionModal"
      ref="captionModal"
      hide-footer
      title="Pripis k sliki"
    >
      <div
        class="pb-5"
        style="height: 100%;"
        v-if="selectedCaptionIndex !== null && photoPreviews.length > 0"
      >
        <b-img
          thumbnail
          :src="photoPreviews[selectedCaptionIndex]"
          alt=""
        ></b-img>

        <b-form-group
          label="Pripis k sliki"
          label-for="caption"
          class="mt-3 mb-4"
        >
          <b-form-input
            autofocus
            type="text"
            id="caption"
            v-model="post.photosCaptions[selectedCaptionIndex]"
            trim
          ></b-form-input>
        </b-form-group>

        <a
          class="btn btn-secondary float-left"
          @click="
            () => {
              $refs['captionModal'].hide();
              post.photosCaptions[
                selectedCaptionIndex
              ] = selectedCaptionBeforeEdit;
              selectedCaptionIndex = null;
            }
          "
          >Prekliči</a
        >
        <a
          class="btn btn-primary float-right"
          @click="
            () => {
              $refs['captionModal'].hide();
              selectedCaptionIndex = null;
            }
          "
          >Potrdi</a
        >
      </div>
    </b-modal>
  </b-container>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase";
// import {db} from "../../main";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueTagsInput from "@johmun/vue-tags-input";
import _ from "lodash";
import { db } from "@/main";
import Loading from "vue-loading-overlay";
// import pako from "pako";
// import Media from "@dongido/vue-viaudio";
// import {createFFmpeg, fetchFile} from "@ffmpeg/ffmpeg";
import { Dropbox } from "dropbox";
import {
  required,
  minLength,
  maxLength,
  requiredIf
} from "vuelidate/lib/validators";

export default {
  name: "CreatePost",
  components: {
    vSelect,
    VueTagsInput,
    Loading
  },
  data() {
    return {
      postId: this.$route.params.postId,
      post: {
        id: null,
        title: null,
        summary: null,
        content: null,
        videos: [],
        photos: [],
        photosCaptions: [],
        recordings: [],
        region: null,
        categoryId: null,
        subcategoryId: null,
        tags: [],
        informant: {
          same_as_user: false,
          firstName: null,
          lastName: null,
          address: null,
          house_name: null,
          education_level: null,
          region_of_birth: null,
          post_background_story: null,
          isNoLongerAlive: false
        },
        createdBy: null,
        createdAt: null,
        modifiedAt: null,
        submitted: false,
        submittedAt: null,
        reviewersComment: null,
        confirmed: false,
        confirmedBy: null,
        confirmedAt: null,
        recordFromNationalRegistry: false,
        geolocation: {
          address: null,
          lat: null,
          lon: null
        },
        connectedPosts: []
      },
      user: null,
      categories: null,
      subcategories: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"]
      ],
      tag: "",
      photos: [],
      photoPreviews: [],
      videos: null,
      videoPreviews: [],
      autocompleteItems: [],
      recordings: null,
      recordingPreviews: [],
      uploadValue: null,
      options: {},
      message: null,
      isLoading: false,
      isLoadingVideo: false,
      isLoadingRecording: false,
      thumbnails: [],
      showPostSource: false,
      selectedCaptionBeforeEdit: null,
      selectedCaptionIndex: null,
      posts: []
    };
  },
  validations: {
    post: {
      title: {
        required,
        minLength: minLength(3)
      },
      summary: {
        required,
        maxLength: maxLength(250)
      },
      region: {
        required
      },
      categoryId: {
        required
      },
      subcategoryId: {
        required: requiredIf(function(val) {
          return val.categoryId != 6;
        })
      }
    }
  },
  watch: {
    videos: function(newVal, oldVal) {
      console.log("newVal", newVal);
      if (newVal && newVal.size > 500000001) {
        this.videos = oldVal;
        this.$swal(
          "Največja dovoljena velikost datoteke je 500 MB.",
          "Prosimo, optimizirajte velikost datoteke oz. jo razdelite na več posameznih datotek.",
          "error"
        );
      }
    },
    recordings: function(newVal, oldVal) {
      console.log("newVal", newVal);
      if (newVal && newVal.size > 200000001) {
        this.recordings = oldVal;
        this.$swal(
          "Največja dovoljena velikost datoteke je 200 MB.",
          "Prosimo, optimizirajte velikost datoteke oz. jo razdelite na več posameznih datotek.",
          "error"
        );
      }
    }
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  destroyed() {
    this.toggleBodyClass("removeClass", "editPostContainer");
  },
  mounted() {
    this.toggleBodyClass("addClass", "editPostContainer");

    const _this = this;
    let p1 = _this.$store.dispatch("setCategories");
    let p2 = _this.$store.dispatch("setTags");
    let p3 = _this.$store.dispatch("setUsers");

    Promise.all([p1, p2, p3]).then(() => {
      console.log("categories", _this.$store.getters.getCategories);
      _this.categories = _this.$store.getters.getCategories;
      _this.subcategories = _this.$store.getters.getSubcategories;
      _this.autocompleteItems = _this.$store.getters.getTags;

      if (_this.postId) {
        _this.retrievePost();

        // retrieve all posts (for connected posts) if admin
        if (_this.getUser.admin) {
          _this.retrievePosts();
        }
      }
    });
  },
  methods: {
    async uploadVideo() {
      const _this = this;

      console.log("videos", _this.videos);

      if (!_this.videos || _this.videos.length === 0) {
        _this.$swal(
          'V zgornje polje dodajte video posnetek in nato kliknite "Naložite".',
          "",
          "error"
        );
        return;
      }

      _this.isLoadingVideo = true;

      const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
      let ACCESS_TOKEN =
        "gDiID073o7UAAAAAAAAAAbqNtrvHu6shGELzZkkG2K2saiLG799bzaPJgGwZ-rOE";
      let dbx = new Dropbox({ accessToken: ACCESS_TOKEN });
      let file = _this.videos;

      console.log("file", file);

      const name = file.name.replace(/\.[^/.]+$/, "");
      const extension = file.name.substring(
        file.name.lastIndexOf("."),
        file.name.length
      );
      const finalFileName = name + "_" + new Date().getTime() + "_" + extension;

      if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
        // File is smaller than 150 Mb - use filesUpload API

        dbx
          .filesUpload({
            path: "/" + finalFileName,
            contents: file,
            autorename: true,
            mute: true
          })
          .then(async function(response) {
            console.log(response);

            const sharedResponse = await dbx
              .sharingCreateSharedLinkWithSettings({
                path: response.result.path_lower,
                settings: {
                  requested_visibility: "public",
                  audience: "public",
                  access: "viewer"
                }
              })
              .catch(error => {
                console.log(error);
              });

            _this.saveDropboxVideoData({
              id: response.result.id,
              url: sharedResponse.result.url.replace("?dl=0", "?raw=1")
            });
            console.log(
              "RESPONSE TO SAVE !!!!",
              sharedResponse.result.url.replace("?dl=0", "?raw=1")
            );
          })
          .catch(function(error) {
            console.error(error);
          });
      } else {
        // File is bigger than 150 Mb - use filesUploadSession* API
        const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

        let workItems = [];

        let offset = 0;

        while (offset < file.size) {
          let chunkSize = Math.min(maxBlob, file.size - offset);
          workItems.push(file.slice(offset, offset + chunkSize));
          offset += chunkSize;
        }

        const task = workItems.reduce((acc, blob, idx, items) => {
          if (idx == 0) {
            // Starting multipart upload of file
            return acc.then(function() {
              return dbx
                .filesUploadSessionStart({ close: false, contents: blob })
                .then(response => response.result.session_id);
            });
          } else if (idx < items.length - 1) {
            // Append part to the upload session
            return acc.then(function(sessionId) {
              let cursor = { session_id: sessionId, offset: idx * maxBlob };
              return dbx
                .filesUploadSessionAppendV2({
                  cursor: cursor,
                  close: false,
                  contents: blob
                })
                .then(() => sessionId);
            });
          } else {
            // Last chunk of data, close session
            return acc.then(function(sessionId) {
              let cursor = {
                session_id: sessionId,
                offset: file.size - blob.size
              };
              let commit = {
                path: "/" + finalFileName,
                mode: "add",
                autorename: true,
                mute: true
              };
              return dbx.filesUploadSessionFinish({
                cursor: cursor,
                commit: commit,
                contents: blob
              });
            });
          }
        }, Promise.resolve());

        task
          .then(async function(response) {
            console.log("response:", response);

            const sharedResponse = await dbx
              .sharingCreateSharedLinkWithSettings({
                path: response.result.path_lower,
                settings: {
                  requested_visibility: "public",
                  audience: "public",
                  access: "viewer"
                }
              })
              .catch(error => {
                console.log(error);
              });

            _this.saveDropboxVideoData({
              id: response.result.id,
              url: sharedResponse.result.url.replace("?dl=0", "?raw=1")
            });
            console.log(
              "RESPONSE TO SAVE !!!!",
              sharedResponse.result.url.replace("?dl=0", "?raw=1")
            );
          })
          .catch(function(error) {
            console.error(error);
          });
      }
      return false;
    },

    saveDropboxVideoData(video) {
      const _this = this;

      console.log("video", video);

      _this.post.videos.push(video);
      //_this.videoPreviews.push(video);
      _this.videos = null;

      _this.$forceUpdate();
      _this.isLoadingVideo = false;
    },

    async uploadRecording() {
      const _this = this;

      if (!_this.recordings || _this.recordings.length === 0) {
        _this.$swal(
          'V zgornje polje dodajte zvočni posnetek in nato kliknite "Naložite".',
          "",
          "error"
        );
        return;
      }

      console.log("recordings", _this.recordings);
      _this.isLoadingRecording = true;

      const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
      let ACCESS_TOKEN =
        "gDiID073o7UAAAAAAAAAAbqNtrvHu6shGELzZkkG2K2saiLG799bzaPJgGwZ-rOE";
      let dbx = new Dropbox({ accessToken: ACCESS_TOKEN });
      let file = _this.recordings;

      console.log("file", file);

      const name = file.name.replace(/\.[^/.]+$/, "");
      const extension = file.name.substring(
        file.name.lastIndexOf("."),
        file.name.length
      );
      const finalFileName = name + "_" + new Date().getTime() + "_" + extension;

      if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
        // File is smaller than 150 Mb - use filesUpload API

        dbx
          .filesUpload({
            path: "/" + finalFileName,
            contents: file,
            autorename: true,
            mute: true
          })
          .then(async function(response) {
            console.log(response);

            const sharedResponse = await dbx
              .sharingCreateSharedLinkWithSettings({
                path: response.result.path_lower,
                settings: {
                  requested_visibility: "public",
                  audience: "public",
                  access: "viewer"
                }
              })
              .catch(error => {
                console.log(error);
              });

            _this.saveDropboxRecordingData(
              sharedResponse.result.url.replace("?dl=0", "?raw=1")
            );
            console.log(
              "RESPONSE TO SAVE !!!!",
              sharedResponse.result.url.replace("?dl=0", "?raw=1")
            );
          })
          .catch(function(error) {
            console.error(error);
          });
      } else {
        // File is bigger than 150 Mb - use filesUploadSession* API
        const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

        let workItems = [];

        let offset = 0;

        while (offset < file.size) {
          let chunkSize = Math.min(maxBlob, file.size - offset);
          workItems.push(file.slice(offset, offset + chunkSize));
          offset += chunkSize;
        }

        const task = workItems.reduce((acc, blob, idx, items) => {
          if (idx == 0) {
            // Starting multipart upload of file
            return acc.then(function() {
              return dbx
                .filesUploadSessionStart({ close: false, contents: blob })
                .then(response => response.result.session_id);
            });
          } else if (idx < items.length - 1) {
            // Append part to the upload session
            return acc.then(function(sessionId) {
              let cursor = { session_id: sessionId, offset: idx * maxBlob };
              return dbx
                .filesUploadSessionAppendV2({
                  cursor: cursor,
                  close: false,
                  contents: blob
                })
                .then(() => sessionId);
            });
          } else {
            // Last chunk of data, close session
            return acc.then(function(sessionId) {
              let cursor = {
                session_id: sessionId,
                offset: file.size - blob.size
              };
              let commit = {
                path: "/" + finalFileName,
                mode: "add",
                autorename: true,
                mute: true
              };
              return dbx.filesUploadSessionFinish({
                cursor: cursor,
                commit: commit,
                contents: blob
              });
            });
          }
        }, Promise.resolve());

        task
          .then(async function(response) {
            console.log("response:", response);

            const sharedResponse = await dbx
              .sharingCreateSharedLinkWithSettings({
                path: response.result.path_lower,
                settings: {
                  requested_visibility: "public",
                  audience: "public",
                  access: "viewer"
                }
              })
              .catch(error => {
                console.log(error);
              });

            _this.saveDropboxRecordingData(
              sharedResponse.result.url.replace("?dl=0", "?raw=1")
            );
            console.log(
              "RESPONSE TO SAVE !!!!",
              sharedResponse.result.url.replace("?dl=0", "?raw=1")
            );
          })
          .catch(function(error) {
            console.error(error);
          });
      }
      return false;
    },

    saveDropboxRecordingData(recording) {
      const _this = this;

      console.log("recording", recording);

      _this.post.recordings.push(recording);
      //_this.videoPreviews.push(video);

      _this.recordings = null;
      _this.$forceUpdate();
      _this.isLoadingRecording = false;
    },

    retrievePost() {
      const _this = this;
      console.log("retrievePost");

      _this.isLoading = true;
      _this.videos = null;
      _this.videoPreviews = [];

      db.collection("posts")
        .doc(_this.postId)
        .get()
        .then(function(querySnapshot) {
          _this.post = querySnapshot.data();
          if (_this.post.photos === null) {
            _this.post.photos = [];
          } else {
            console.log("_this.post.photos retrieved:", _this.post.photos);
            _this.photoPreviews = _.filter(_this.post.photos, function(photo) {
              if (photo.includes("750x470")) {
                return photo;
              }
            });
            _this.post.photos = _this.photoPreviews;

            if (
              typeof _this.post.photosCaptions === "undefined" ||
              !_this.post.photosCaptions ||
              _this.post.photosCaptions === null
            ) {
              _this.post.photosCaptions = new Array(
                _this.photoPreviews.length
              ).fill(null);
            }
          }

          if (
            typeof _this.post.videos === "undefined" ||
            !_this.post.videos ||
            _this.post.videos === null
          ) {
            _this.post.videos = [];
            _this.videoPreviews = [];
          } else {
            _this.videoPreviews = _this.post.videos ? _this.post.videos : [];

            _.each(_this.post.videos, async function(val, _index) {
              let fileName = "/" + /[^/]*$/.exec(val.url)[0];
              fileName = fileName.split("?")[0];
              fileName = decodeURI(fileName);

              let res = await _this.$globalFunctions.getDropboxThumbnail(
                fileName
              );

              _this.thumbnails[_index] = res;
              _this.$forceUpdate();
            });
          }

          if (
            typeof _this.post.recordings === "undefined" ||
            !_this.post.recordings ||
            _this.post.recordings === null
          ) {
            _this.post.recordings = [];
            _this.recordingPreviews = [];
          } else {
            _this.recordingPreviews = _this.post.recordings
              ? _this.post.recordings
              : [];
          }

          if (typeof _this.post.geolocation === "undefined") {
            _this.post.geolocation = {
              address: null,
              lat: null,
              lon: null
            };
          }

          if (typeof _this.post.connectedPosts === "undefined") {
            _this.post.connectedPosts = [];
          }

          _this.user = _this.$globalFunctions.getUserById(_this.post.createdBy);
          _this.isLoading = false;

          console.log("Post data: ", JSON.stringify(_this.post));
        });
    },

    submitPost() {
      const _this = this;

      if (_this.checkForNotUploadedAttachments()) {
        return;
      }

      this.$swal({
        title: "Ali ste prepričani, da želite objaviti zapis?",
        text:
          "Po potrditvi zapisa ne bo več mogoče spreminjati. Za popravke/dopolnitve pišite na info@dobra-pot.si. \n\nV kolikor želite lahko v spodnje vnosno polje dopišete komentar, ki bo posredovan uredniku.",
        content: { element: "textarea", elementLabel: "Lorem" },
        icon: "warning",
        type: "warning",
        buttons: {
          cancel: {
            text: "Prekliči",
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Oddaj",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(value => {
        switch (value) {
          case true: {
            _this.post.submitted = true;
            _this.post.submittedAt = new Date();

            let authorsMessage = document.querySelector(
              ".swal-content__textarea"
            ).value;

            if (_this.post.id) {
              _this.updatePost().then(() => {
                _this
                  .sendEmailToAdmins("submitted", authorsMessage)
                  .then(() => {
                    _this
                      .sendEmailToUser("submitted", authorsMessage)
                      .then(() => {
                        _this.$router.push({ name: "MyPosts" });
                      });
                  });
              });
            } else {
              _this.savePost().then(() => {
                _this.sendEmailToUser("submitted");
              });
            }

            break;
          }
        }
      });
    },

    async confirmPost() {
      const _this = this;

      if (_this.checkForNotUploadedAttachments()) {
        return;
      }

      _this.isLoading = true;
      _this.post.confirmed = true;
      _this.post.confirmedAt = new Date();
      _this.post.confirmedBy = this.$store.getters.getUser.id;

      _this.updatePost().then(() => {
        _this.sendEmailToUser("confirmed").then(() => {
          _this.axios
            .get(
              "https://us-central1-zapisispomina.cloudfunctions.net/firestoreToAlgolia"
            )
            .then(resp => {
              console.log("resp", resp);
              _this.isLoading = false;
              _this.$router.push({ name: "ConfirmedPosts" });
            })
            .catch(err => {
              console.log(err);
              _this.isLoading = false;
            });
        });
      });
    },

    returnPostToAuthor() {
      const _this = this;

      this.$swal({
        title: "Ali ste prepričani, da želite vrniti zapis v urejanje avtorju?",
        text:
          "V kolikor želite lahko v spodnje vnosno polje dopišete komentar, ki bo posredovan avtorju.",
        content: { element: "textarea", elementLabel: "Lorem" },
        icon: "warning",
        type: "warning",
        buttons: {
          cancel: {
            text: "Prekliči",
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Oddaj",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(value => {
        switch (value) {
          case true: {
            let editorsMessage = document.querySelector(
              ".swal-content__textarea"
            ).value;

            _this.isLoading = true;
            _this.post.confirmed = false;
            _this.post.confirmedAt = null;
            _this.post.confirmedBy = null;
            _this.post.submitted = false;
            _this.post.submittedAt = null;
            _this.post.submittedBy = null;

            _this.updatePost().then(() => {
              _this.sendEmailToUser("returned", editorsMessage).then(() => {
                _this.isLoading = false;
                _this.$router.push({ name: "SubmittedPosts" });
              });
            });

            break;
          }
        }
      });
    },

    unconfirmPost() {
      const _this = this;

      _this.isLoading = true;
      _this.post.confirmed = false;
      _this.post.confirmedAt = null;
      _this.post.confirmedBy = null;

      _this.updatePost().then(() => {
        _this.isLoading = false;
        _this.$router.push({ name: "SubmittedPosts" });
      });
    },

    async sendEmailToUser(eventType, editorsMessage) {
      const _this = this;

      if (!_this.user.allowEmailNotifications) {
        console.log("User does not allow emails!");
        return true;
      }

      let post = _.cloneDeep(_this.post);
      let subject = "";
      let html = "";
      console.log("post", post);

      if (eventType === "submitted") {
        subject = "Zapisi spomina: Vaš zapis uspešno oddan";
        html =
          _this.user.displayName +
          ",<br/> vaš zapis <b>" +
          post.title +
          "</b> je bil uspešno oddan v pregled. <br/><br/>Objavljen bo v najkrajšem možnem času. <br/><br/>ekipa Zavoda Dobra pot";
      } else if (eventType === "confirmed") {
        subject = "Zapisi spomina: Vaš zapis je objavljen!";
        html =
          _this.user.displayName +
          ",<br/> vaš zapis <b>" +
          post.title +
          '</b> je bil pregledan in je že objavljen! Dostopen je na tej povezavi: <a href="https://zapisi-spomina.dobra-pot.si/zapis/' +
          post.id +
          '">' +
          post.title +
          "</a>. <br/><br/>Ekipa Zavoda Dobra pot";
      } else if (eventType === "returned") {
        subject = "Zapisi spomina: Urednik vam je zapis vrnil v urejanje!";
        html =
          _this.user.displayName +
          ",<br/> vaš zapis <b>" +
          post.title +
          "</b> je bil pregledan in vam je vrnjen v urejanje. <br/><br/>Urednikov komentar: " +
          editorsMessage +
          ' <br/><br/>Do zapisa lahko dostopate na tej povezavi: <a href="https://zapisi-spomina.dobra-pot.si/app/moji-zapisi/"> Moji zapisi</a>. <br/><br/>Ekipa Zavoda Dobra pot';
      }

      console.log("Creating record for email!");
      let ref = db.collection("emails").doc();
      return ref
        .set({
          id: ref.id,
          to: _this.user.email,
          message: {
            subject: subject,
            html: html
          }
        }) // set new document ID
        .then(() => {
          console.log("Record for email created!");
        });
    },

    async sendEmailToAdmins(eventType, authorsMessage) {
      const _this = this;

      let post = _.cloneDeep(_this.post);
      let subject = "";
      let html = "";
      console.log("post", post);

      if (eventType === "submitted") {
        subject = "Zapisi spomina: Nov zapis oddan v pregled";
        html =
          "Oseba " +
          _this.user.displayName +
          " je v pregled oddala zapis z naslovom: <b>" +
          post.title +
          "</b>. <br/><br/>Sporočilo avtorja: " +
          authorsMessage +
          "<br/><br/>Zapis je viden " +
          '<a href="https://zapisi-spomina.dobra-pot.si/admin/zapisi-v-cakanju-na-potrditev">na seznamu zapisov v čakanju na potrditev</a>.' +
          "<br/><br/>Zapis je potrebno čim prej pregledati in ga objaviti oz. avtorju sporočiti povratno infromacijo.";
      }

      console.log("Creating record for email!");
      let ref = db.collection("emails").doc();
      return ref
        .set({
          id: ref.id,
          to: "sitka.tepeh@dobra-pot.si, nava.vardjan@dobra-pot.si",
          //to: "marko.zevnik@dobra-pot.si",
          message: {
            subject: subject,
            html: html
          }
        }) // set new document ID
        .then(() => {
          console.log("Record for email created! ", ref.id);
        });
    },

    async savePost() {
      const _this = this;

      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        this.$nextTick(() => this.focusFirstStatus());
        return;
      }

      if (_this.checkForNotUploadedAttachments()) {
        return;
      }

      _this.isLoading = true;
      window.scrollTo({ top: 0, behavior: "smooth" });

      let ref = db.collection("posts").doc();
      return ref
        .set({ id: ref.id }) // set new document ID
        .then(() => {
          // fetch the doc again and show its data
          ref.get().then(doc => {
            _this.post.id = doc.data().id;
            _this.post.createdAt = new Date();
            _this.post.createdBy = this.$store.getters.getUser.id;
            _this.post.deleted = false;
            _this.post.likes = [
              {
                userId: "ZapisiSpominaSystem",
                likedAt: firebase.firestore.Timestamp.now()
              }
            ];
            _this.updatePost();
            console.log("POST CREATED!");
          });
        });
    },

    async updatePost() {
      const _this = this;

      if (_this.checkForNotUploadedAttachments()) {
        return;
      }

      this.$v.$touch();
      this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        return;
      }

      _this.isLoading = true;
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (_this.photos.length > 0) {
        await _this.multipleUploadTask();
      }
      // if (_this.videos.length > 0) {
      //   await _this.multipleVideoUploadTask();
      // }
      console.log("grem naprej");
      console.log("post data: ", _this.post);

      const post = db.collection("posts").doc(_this.post.id);

      return post
        .update({
          id: _this.post.id,
          createdAt: _this.post.createdAt,
          createdBy: _this.post.createdBy,
          title: _this.post.title,
          en_title: _this.post.en_title || "",
          summary: _this.post.summary,
          en_summary: _this.post.en_summary || "",
          content: _this.post.content,
          en_content: _this.post.en_content || "",
          videos: _this.post.videos,
          photos: _this.post.photos
            ? _.filter(_this.post.photos, function(photo) {
                if (!photo.includes("blob")) {
                  return photo;
                }
              })
            : [],
          photosCaptions: _this.post.photosCaptions,
          recordings: _this.post.recordings ? _this.post.recordings : [],
          region: _this.post.region,
          categoryId: _this.post.categoryId,
          subcategoryId: _this.post.subcategoryId,
          tags: _this.post.tags,
          informant: {
            same_as_user: _this.post.informant.same_as_user,
            firstName: _this.post.informant.firstName,
            lastName: _this.post.informant.lastName,
            address: _this.post.informant.address,
            house_name: _this.post.informant.house_name,
            education_level: _this.post.informant.education_level,
            region_of_birth: _this.post.informant.region_of_birth,
            post_background_story: _this.post.informant.post_background_story,
            isNoLongerAlive: _this.post.informant.isNoLongerAlive
          },
          modifiedAt: new Date(),
          submitted: _this.post.submitted,
          submittedAt: _this.post.submittedAt,
          reviewersComment: _this.post.reviewersComment
            ? _this.post.reviewersComment
            : null,
          confirmed: _this.post.confirmed,
          confirmedBy: _this.post.confirmedBy,
          confirmedAt: _this.post.confirmedAt,
          deleted: _this.post.deleted,
          likes: _this.post.likes,
          recordFromNationalRegistry: _this.post.recordFromNationalRegistry,
          geolocation: this.post.geolocation,
          connectedPosts: this.post.connectedPosts
        })
        .then(function() {
          console.log("post updated!");

          _this.postId = _this.post.id;

          _this.post.photos = [];
          _this.post.videos = [];
          _this.post.recordings = [];
          _this.photos = [];
          _this.photoPreviews = [];
          _this.videos = [];
          _this.videoPreviews = [];

          _this.retrievePost();
          // _this.$store.dispatch("retrieveUsers", true).then(() => {
          //   _this.$router.push("/uporabniki");
          // });

          _this.$swal("Vsebina uspešno shranjena 👍", "", "success");

          _this.isLoading = false;
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating user: ", error);
          _this.isLoading = false;
        });
    },
    previewImage(event) {
      const _this = this;

      // console.log("PREVIEW IMAGES!!!!", event.dataTransfer.files);
      let files = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;

      _.each(files, function(val) {
        console.log("val", val);
        _this.photoPreviews.push(URL.createObjectURL(val));
        _this.photos.push(val);
        _this.post.photosCaptions.push(null);
      });
    },

    async multipleUploadTask() {
      const _this = this;

      await Promise.all(
        await _this.photos.map(async item => {
          console.log("item", item);
          // if (item.includes("blob")) {
          await _this.uploadImage(item);
          // }
        })
      );

      console.log("HERE!");
    },

    async uploadImage(photo) {
      const _this = this;

      const storageRef = firebase
        .storage()
        .ref("images/" + _this.post.id)
        .child(photo.name)
        .put(photo);

      return new Promise((resolve, reject) => {
        storageRef.on(
          `state_changed`,
          snapshot => {
            _this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error.message);
            reject(error);
          },
          () => {
            _this.uploadValue = 100;
            // storageRef.snapshot.ref.getDownloadURL().then(url => {
            //   _this.post.photos.push(url);
            //   console.log("image url:", url);
            //   resolve(url);
            // });

            // const name = photo.name.substring(
            //   photo.name.lastIndexOf("/") + 1,
            //   photo.name.indexOf(".")
            // );
            // const re = /(?:\.([^.]+))?$/;
            const name = photo.name.replace(/\.[^/.]+$/, "");

            console.log("name", name);
            let urlStr = name + "_" + 750 + "x" + 470 + ".jpeg";
            console.log("urlStr", urlStr);

            setTimeout(() => {
              const storageThumbnailRef = firebase
                .storage()
                .ref("images/" + _this.post.id)
                .child(urlStr);

              storageThumbnailRef.getDownloadURL().then(url => {
                if (url.includes("750x470")) {
                  _this.post.photos.push(url);
                  console.log("image url:", url);
                }
                resolve(url);
              });
            }, 5000);
          }
        );
      });
    },

    prefillInformantFields() {
      const _this = this;

      if (_this.post.informant.same_as_user) {
        _this.post.informant.firstName = _this.$store.getters.getUser.firstName;
        _this.post.informant.lastName = _this.$store.getters.getUser.lastName;
        _this.post.informant.isNoLongerAlive = false;
        _this.$forceUpdate();
      }
    },

    getSubcategories() {
      const _this = this;

      if (!_this.categories) {
        return;
      }

      _this.subcategories = [];

      if (_this.post.categoryId) {
        let filteredCategories = [];

        _.each(_this.$store.getters.getSubcategories, function(val) {
          if (
            val.id &&
            val.id.substring(0, 1) === _this.post.categoryId.toString()
          ) {
            filteredCategories.push(val);
          }
        });
        _this.subcategories = filteredCategories;
      } else {
        _this.subcategories = _this.$store.getters.getSubcategories;
      }
    },
    setCategory() {
      const _this = this;

      if (_this.post.subcategoryId && !_this.post.categoryId) {
        _this.post.categoryId = parseInt(
          _this.post.subcategoryId.substring(0, 1)
        );
        _this.getSubcategories();
      }
    },
    removeImage(img, idx) {
      const _this = this;

      console.log("remove image", img);
      _this.photoPreviews.splice(idx, 1);
      _this.photos.splice(idx, 1);
      _this.post.photosCaptions.splice(idx, 1);
    },

    removeVideo(video, idx) {
      const _this = this;

      console.log("remove video", video);
      _this.videoPreviews.splice(idx, 1);
      _this.video.splice(idx, 1);
    },

    removeRecording(recording, idx) {
      const _this = this;

      console.log("remove recording", recording);
      _this.recordingPreviews.splice(idx, 1);
      _this.recordings.splice(idx, 1);
    },

    beforeDeletePost() {
      const _this = this;

      this.$swal({
        title: "Ali ste prepričani, da želite izbrisati zapis?",
        text: "Po potrditvi bo zapis trajno izbrisan.",
        icon: "warning",
        type: "warning",
        buttons: {
          cancel: {
            text: "Prekliči",
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Izbriši",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(value => {
        switch (value) {
          case true:
            _this.deletePost();

            break;
        }
      });
    },

    deletePost() {
      const _this = this;

      _this.isLoading = true;

      const post = db.collection("posts").doc(_this.post.id);

      return post
        .update({
          submitted: false,
          submittedAt: null,
          confirmed: false,
          confirmedBy: null,
          confirmedAt: null,
          deleted: true
        })
        .then(function() {
          console.log("post marked as delted!");

          _this.axios
            .get(
              "https://us-central1-zapisispomina.cloudfunctions.net/firestoreToAlgolia"
            )
            .then(resp => {
              console.log("resp", resp);
              _this.isLoading = false;
              _this.$router.go(-1);
            })
            .catch(err => {
              console.log(err);
              _this.isLoading = false;
            });
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating user: ", error);
          _this.isLoading = false;
        });
    },

    async onPlayerDataLoaded(e, index) {
      console.log("onPlayerDataLoaded.posterImage", e);
      console.log("index", index);
    },

    editCaption(idx) {
      const _this = this;

      console.log("idx", idx);

      _this.selectedCaptionBeforeEdit = _this.post.photosCaptions[idx]
        ? _this.post.photosCaptions[idx]
        : "";
      _this.selectedCaptionIndex = idx;

      _this.$refs["captionModal"].show();
    },

    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },

    checkForNotUploadedAttachments() {
      const _this = this;

      let attachmentsNotLoaded = false;

      if (_this.videos) {
        _this.$swal(
          "Izbrali ste pripenjanje video posnetka " +
            _this.videos.name +
            ", a ga niste naložili.",
          'Če ga želite dodatki k zapisu, izberite možnost "Naložite video posnetek". Če ga ne želite dodati, izberite možnost "Prekliči".',
          "error"
        );
        attachmentsNotLoaded = true;
        document
          .getElementById("videosSection")
          .scrollIntoView({ behavior: "smooth" });
      }

      if (_this.recordings) {
        _this.$swal(
          "Izbrali ste pripenjanje zvočnega posnetka " +
            _this.recordings.name +
            ", a ga niste naložili.",
          'Če ga želite dodatki k zapisu, izberite možnost "Naložite video posnetek". Če ga ne želite dodati, izberite možnost "Prekliči".',
          "error"
        );
        attachmentsNotLoaded = true;
        document
          .getElementById("recordingsSection")
          .scrollIntoView({ behavior: "smooth" });
      }

      return attachmentsNotLoaded;
    },

    focusFirstStatus() {
      if (document.querySelector('.error')) {
        let domRect = document.querySelector('.error').getBoundingClientRect();
        window.scrollTo(
          {
            top: domRect.top + document.documentElement.scrollTop - 200,
            left: domRect.left + document.documentElement.scrollLeft,
            behavior: 'smooth'
          }
        );
      }
    },

    setGeolocationData(data) {
      this.post.geolocation.address = data.formatted_address;
      this.post.geolocation.lat = data.geometry.location.lat();
      this.post.geolocation.lon = data.geometry.location.lng();
      this.$forceUpdate();
    },

    // for setting connectd posts
    async retrievePosts() {
      const _this = this;

      _this.isLoading = true;

      let query = db.collection("posts");
      query = query.where("confirmed", "==", true);

      await query
        .orderBy("confirmedAt", "desc")
        .get()
        .then(function(_docs) {
          console.log(_docs);
          let posts = _docs.docs.map(doc => doc.data());
          _this.posts = posts;
          // console.log("posts", posts);
          _this.isLoading = false;
        })
        .catch(error => {
          console.log("Error getting categories:", error);
          _this.isLoading = false;
        });
    }
  }
};
</script>
<style>
/*.vs__search, .vs__search:focus {*/
/*  width: 100%;*/
/*}*/
</style>
